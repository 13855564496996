import React from "react";

const Poppy = () => {
  return (
    <div className="relative m-4 p-4">
      <div>
        <h1 className="text-rose-700">Remembrance</h1>
        <br />
        <p>
          We honour and remember our fallen Veterans and help to ensure that the
          sacrifices these men and women made for us and their country are never
          forgotten.
        </p>
        <br />
        <p>
          Branch 37 is responsible for the Remembrance Ceremony held in Sidney
          on November 11, following a Parade to the Cenotaph at the Sidney Town
          Offices. Details of the ceremony are published each year at the Legion
          office and in the local media.
        </p>
        <br />
        <p>To contact the poppy office you can e-mail <a className="text-rose-600" href="mailto:poppy@peninsulalegion.ca">
              poppy@peninsulalegion.ca
            </a></p>
      </div>
      <br />
      <br />
      <div className="">
      <h2 className="text-lg font-extrabold">
          2024 Saanich Peninsula Legion Poppy Campaign
        </h2>
        <br />
        <p>
          The 2024 Poppy Campaign will be getting underway again and we will soon announce our office location and hours.
        </p>
        <br />
 {/*}
        <h2 className="text-lg font-extrabold">
          2024 Saanich Peninsula Legion Poppy Campaign
        </h2>
        <br />
        <p>
          The 2024 Poppy Campaign is underway again and we have opened our
          office in Sidney at 9802 Fifth Street, at Beacon Ave. We are staffed
          by volunteers each day from 10:00am - 4:00pm, closed Sundays.
        </p>
        <br />
        <p>
          We have a number of items for sale in the Poppy Office including
          Poppies, Legion souvenirs, and other remembrance apparel.
          Organizations may also purchase wreaths for the Remembrance Day
          Ceremony in Sidney on November 11th. Our Wreath pricing is as follows:
        </p>
{*/}        
        <div className="mt-4 -mb-3">
          <div className="not-prose relative bg-slate-50 rounded-xl overflow-hidden ">
            <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] "></div>
            <div className="relative rounded-xl overflow-auto">
              <div className="shadow-sm overflow-hidden my-8">
                <table className="border-collapse table-auto w-full text-sm">
                  <thead>
                    <tr>
                      <th className="border-b  font-medium p-4 pl-8 pt-0 pb-3 text-rose-700  text-left">
                        Size
                      </th>
                      <th className="border-b  font-medium p-4 pl-8 pt-0 pb-3 text-rose-700  text-left">
                        Ribbon
                      </th>
                      <th className="border-b  font-medium p-4 pl-8 pt-0 pb-3 text-rose-700  text-left">
                        Cost
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white ">
                    <tr>
                      <td className="border-b border-slate-100  p-4 pl-8 text-slate-500 ">
                        #8 - Wreath
                      </td>
                      <td className="border-b border-slate-100  p-4 text-slate-500 ">
                        too small for a ribbon
                      </td>
                      <td className="border-b border-slate-100  p-4 pr-8 text-slate-500 ">
                        $32.00
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-slate-100  p-4 pl-8 text-slate-500 ">
                        #14 - Wreath
                      </td>
                      <td className="border-b border-slate-100  p-4 text-slate-500 ">
                        plus $10 for ribbon
                      </td>
                      <td className="border-b border-slate-100 p-4 pr-8 text-slate-500 ">
                        $62.00
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-slate-100  p-4 pl-8 text-slate-500 d">
                        #20 - Wreath
                      </td>
                      <td className="border-b border-slate-100  p-4 text-slate-500 ">
                        plus $10 for ribbon
                      </td>
                      <td className="border-b border-slate-100  p-4 pr-8 text-slate-500 ">
                        $94.00
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-slate-100  p-4 pl-8 text-slate-500 ">
                        #24 - Wreath
                      </td>
                      <td className="border-b border-slate-100  p-4 text-slate-500 ">
                        plus $10 for ribbon
                      </td>
                      <td className="border-b border-slate-100  p-4 pr-8 text-slate-500 ">
                        $140.00
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <p>
          Wreath layers are requested to form up at the Sidney Cenotaph by
          10:45am on November 11th.
        </p>
        <br />
        <p>Volunteers are welcome!</p>
        <br />
        <p>The various duties volunteers can assist with:</p>
        <div className="relative">
          <ul className="list-disc list-outside text-slate-700 bg-white p-4 pl-8 ">
            <li>
              Deliver Poppy trays, when requested, to the community through
              schools, community organizations and local businesses.
            </li>
            <li>Phoning committee at least a month prior to Campaign start.</li>
            <li>
              Assist at Poppy Headquarters to distribute poppies and trays to
              volunteers
            </li>
            <li>Collection and counting of money from collection boxes</li>
            <li>
              Manning various business locations with poppy trays offering
              poppies for a donation to the public such as Thrifty Foods,
              Save-on-Foods, and Country Grocer.
            </li>
            <li>
              Drive volunteers without vehicles to their designated station.
            </li>
            <li>After campaign, pick up boxes at business establishments</li>
            <li>
              Any other duties that may be assigned by the Poppy Chairperson
            </li>
          </ul>
        </div>
        <p></p>
        <p></p>
        <p></p>
        <br />
        <p>
          We ask for Two-Hour Shifts. If you could do more - that would be
          GREAT! The more volunteers we have the less the need for multiple
          shifts. Therefore, if you wish to VOLUNTEER your time, PLEASE 
          email <a className="col-span-2 h-14 text-rose-700" href="mailto:poppy@peninsulalegion.ca">
                poppy@peninsulalegion.ca
              </a>
              . Your time will be greatly appreciated!
        </p>
        <br />
        <p>What does my donation to the Poppy Campaign do?</p>
        <br />
        <p>
          Poppy Donations are used to support and improve life for veterans and
          their families living in the Western Communities include:
        </p>
        <div>
          <ul className="list-disc list-outside text-slate-700 bg-white p-4 pl-8 ">
            <li>
              Providing assistance to ex-service members, widows, widowers, and
              their families in situations caused by unforeseen circumstances
            </li>
            <li>
              P​Assisting in affordable housing for veterans and senior citizens
            </li>
            <li>
              Purchasing and providing of medical equipment and appliances for
              community health facilities
            </li>
            <li>
              Repairs and maintenance for wheelchairs and electric scooters
            </li>
            <li>
              {" "}
              ​Providing support services to senior citizens (drop-in
              centres...etc.)
            </li>
            <li>Shelter, food and living expenses</li>
            <li>Awarding of bursaries for local students</li>
            <li>
              Awarding prizes for literary and poster contests to school
              students
            </li>
          </ul>
        </div>
        <br />
      </div>
    </div>
  );
};
export default Poppy;
